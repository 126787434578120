import { h, Element } from "atomico";

export class Tag extends Element {
    constructor() {
        super();
        this.style.width = "100%";
        this.style.height = "100%";
        this.style.position = "fixed";
        this.style.left = "0px";
        this.style.top = "0px";
        this.style.display = "block";
        this.style.zIndex = 200;
    }
    hidden() {
        this.style.display = "none";
    }
    static get props() {
        return ["hello"];
    }
    render() {
        return (
            <div
                style={{
                    position: "fixed",
                    left: "0px",
                    top: "0px",
                    width: "100%",
                    height: "100%",
                    display: this.state.hidden ? "none" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "row wrap",
                    overflow: "auto",
                    background: "rgba(0,0,0,.5)",
                    padding: "80px 1rem",
                    boxSizing: "border-box"
                }}
                click={() => {
                    this.hidden();
                }}
            >
                <div
                    style={{
                        position: "relative"
                    }}
                >
                    <button
                        click={() => {
                            this.hidden();
                        }}
                        style={{
                            background: "transparent",
                            border: "none",
                            position: "absolute",
                            top: "1rem",
                            right: "1rem",
                            cursor: "pointer"
                        }}
                    >
                        <svg width="30px" viewBox="0 0 612 612">
                            <path
                                d="M612 306C612 137.004 474.995 0 306 0 137.004 0 0 137.004 0 306c0 168.995 137.004 306 306 306 168.995 0 306-137.005 306-306zM168.3 424.032L286.333 306 168.3 187.967l19.667-19.667L306 286.333 424.032 168.3l19.668 19.667L325.667 306 443.7 424.032 424.032 443.7 306 325.667 187.967 443.7 168.3 424.032z"
                                fill="#FFF"
                            />
                        </svg>
                    </button>

                    <slot
                        name="image"
                        style={{
                            width: "auto",
                            maxWidth: "720px",
                            maxHeight: "70vh",
                            boxShadow: "0px 22px 32px rgba(0,0,0,.15)"
                        }}
                    />
                    <slot
                        name="title"
                        style={{
                            width: "100%",
                            maxWidth: "620px",
                            margin: "1rem auto",
                            textAlign: "center",
                            fontFamily: "unset",
                            color: "white",
                            fontSize: "26px"
                        }}
                    />
                    <slot
                        name="message"
                        style={{
                            width: "100%",
                            maxWidth: "620px",
                            margin: "1rem auto",
                            textAlign: "center",
                            fontFamily: "unset",
                            color: "white"
                        }}
                    />
                </div>
            </div>
        );
    }
}

customElements.define("atom-popup", Tag);
